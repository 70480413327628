'use strict';

/*global Modernizr */
import store from './_store';
import helpers from './_helpers';
import ResponsiveDebug from './class/ResponsiveDebug';
import Collapsible from './class/Collapsible';
import ScrollAnchor from './class/ScrollAnchor';
import ShowPassword from './class/ShowPassword';
import ClearInput from './class/ClearInput';
import DetectBrowser from './class/DetectBrowser';
import Cookies from './class/Cookies';
import BannerMessages from './class/BannerMessages';
import ValidForm from './class/ValidForm';
// import AnimatedLabelField from './class/AnimatedLabelField';
//import Colorbox from './class/Colorbox';


/**
 *
 * App
 * Main Controller
 *
 * @author acti
 * (vincegobelins, mha, efr, ...)
 */

const app = {
  init: function () {
    if ($('.onlyMarmite').length) {
      console.warn('/!\\ If you see this warning message, it means that your are in Marmite Styleguide.\n' +
        'If it\'s not the case, it means that someone has forgot to remove the class .onlyMarmite in dev template\n' +
        'and many js dev code won\'t work properly. :\'(');
    }

    this.bindUI();

    /*--- initialisation des tailles de viewport ---*/
    store.currentWidth = store.wWidth = helpers.viewport().width;
    store.currentHeight = store.wHeight = helpers.viewport().height;

    store.wScroll = $(window).scrollTop();


    document.addEventListener('DOMContentLoaded', function () {
      const burger = document.querySelectorAll('.navbar-burger');
      const menu = document.querySelectorAll('.navbar-menu');
      const iconburger = document.getElementById('nav-icon');

      const menuItems = document.querySelectorAll('.menuItem');
      if (menuItems.length) {
        for (var f = 0; f < menuItems.length; f++) {
          menuItems[f].addEventListener('click', function () {
            var idMenu = this.getAttribute('data-id');
            if ($('.selected')[0]) {
              $('.selected').removeClass('selected');
            } else {
              this.classList.add('selected');
            }
            if (idMenu) //HasChildren
              $('.top-menu-mobile').addClass('open');
              $('.returnButton').toggle();
              $('.menuItem').toggle();
              $('.submenu-lvl' + idMenu).toggle();
          });
        }
      }

      $('.returnButton').click(function () {
        var idMenu = this.getAttribute('data-id');
        $('.selected').removeClass('selected');

        if (idMenu) //HasChildren
        {
          $('.top-menu-mobile').addClass('open');
          $('.returnButton').toggle();
          $('.menuItem').toggle();
          $('.submenu-lvl' + idMenu).toggle();
        }

        $('.top-menu-mobile').removeClass('open');
      });

      if (burger.length && menu.length) {
        for (var i = 0; i < burger.length; i++) {
          burger[i].addEventListener('click', function () {
            iconburger.classList.toggle('open-menu');
            $('body').toggleClass('open-menu');
            if ($(iconburger).hasClass('open-menu')) {
              $('main').prepend('<div class="backdrop"></div>');
              $('div.backdrop').on('click', function () {
                $(burger).click();
              });
            }
            else
              $('main div.backdrop').remove();
            for (var j = 0; j < menu.length; j++) {
              menu[j].classList.toggle('hidden-menu');

            }
          });
        }
      }
    });



    let self = this;


    /*--- responsive-debug ---*/
    let responsiveDebug = new ResponsiveDebug();

    /*--- detectBrowser ---*/
    let detectBrowser = new DetectBrowser();

    /*--- Validation Form ---*/
    let validForm = new ValidForm({
      /*container: '.valid-form',
      fieldContainer: '.form-field',
      validClass: 'as--valid',
      invalidClass: 'as--invalid',
      msgErrorClass: 'form-msg-error',*/
    });

    /*--- cookies ---*/
    store.cookies = new Cookies();

    /*--- Banner messages (cookies consent / warnig / news...) ---*/
    const messagesBanner = new BannerMessages(/*{
      //caping: 3,
    }*/);

    /*--- Skip links ---*/
    let skip = new Collapsible({
      container: '.skip',
      cta: '.skip-cta',
      className: 'as--focused',
      optionHover: true
    });

    /*--- colorbox ---*/
    /*let colorbox = new Colorbox();*/

    /*--- animation scroll ---*/
    /* Use '.js-scroll-anchor' class to trigger smooth anchor scroll*/
    store.scrollAnchor = new ScrollAnchor();

    /*--- password ---*/
    let showPassword = new ShowPassword();

    /*--- clear input ---*/
    let clearInput = new ClearInput();

    /*--- animated label ---*/
    // let form = new AnimatedLabelField();

    // responsive
    self.onResize();

    //AJAX
    self.load_more_news();
  },

  checkMobile: function () {
    if (/Android|webOS|iPhone|iPad|BlackBerry|Windows Phone|Opera Mini|IEMobile|Mobile/i.test(navigator.userAgent)) {
      return true;
    }
  },

  onResize: function () {
    let self = this;
  },

  onScroll: function () {
    let self = this;
  },

  bindUI: function () {
    let self = this;

    if (Modernizr.mq('only all')) {
      $(window).on('resize', function () {
        store.wWidth = helpers.viewport().width;
        store.wHeight = helpers.viewport().height;
        if (store.currentHeight !== store.wHeight || store.currentWidth !== store.wWidth) {
          store.currentHeight = store.wHeight;
          store.currentWidth = store.wWidth;
          /*--- timer pour le redimensionnement d'ecran ---*/
          clearTimeout(store.timerResponsive);
          store.timerResponsive = setTimeout(self.onResize.bind(self), 300);
        }
      });
    }

    document.onreadystatechange = function () {
      if (document.readyState === 'complete') {
        self.onResize();
      }
    };

    /*--- fonctions au scroll ---*/
    $(window).on('scroll', function () {
      store.wScroll = $(window).scrollTop();

      self.onScroll();
    });

    /*SMOOTH ANCHOR SCROLL*/

    if ($('.page').length) {
      document.querySelectorAll('a[href^="#"]').forEach(anchor => {
        anchor.addEventListener('click', function (e) {
          e.preventDefault();

          document.querySelector(this.getAttribute('href')).scrollIntoView({
            behavior: 'smooth'
          });
        });
      });
    }

    /* SCROLL */

    const body = document.body;
    const scrollUp = 'scroll-up';
    const scrollDown = 'scroll-down';
    let lastScroll = 0;
    window.addEventListener('scroll', () => {
      const currentScroll = window.pageYOffset;

      if (window.matchMedia('(min-width: 897px)').matches) {
        if (currentScroll <= 57) {
          body.classList.remove(scrollUp);
          $('.fixed-header').removeClass('is-visible');
          $('.header-inner').removeClass('is-fixed');
          return;
        }

        if (currentScroll > 57) {
          if ($(body).hasClass('formations-page')) {
            $('.fixed-header').addClass('is-visible');
          } else {
            $('.header-inner').addClass('is-fixed');
          }
        }
      }

      if (currentScroll > lastScroll && !body.classList.contains(scrollDown)) {
        // down
        body.classList.remove(scrollUp);
        body.classList.add(scrollDown);
      } else if (currentScroll < lastScroll && body.classList.contains(scrollDown)) {
        // up
        body.classList.remove(scrollDown);
        body.classList.add(scrollUp);
      }
      lastScroll = currentScroll;
    });

    /* ANIM JS */

    $('.js-item-inview').each(function (i, itemInview) {
      const $itemView = $(itemInview);
      $itemView.one('inview', function (event, isInView) {
        if (isInView) {
          $itemView.addClass('is-inview');
        }
      });
    });

    /* HEADER */

    /*THREE LEVEL MENU HOVER*/
    if (window.matchMedia('(min-width: 897px)').matches) {
      $('.link-container > .menu-link-container')
        .mouseenter(function () {
          $(this).siblings().removeClass('is-active');
          $(this).addClass('is-active');

          /*$(this).find('.second-menu-link').mouseenter(function () {
            $(this).siblings().removeClass('is-active');
            $(this).addClass('is-active');
          })
            .mouseleave(function () {
              $(this).removeClass('is-active');
            });*/
        })
        .mouseleave(function () {
          $(this).removeClass('is-active');
        });

      /* $('.link-container>.two-level-menu').mouseenter(function () {
         $(this).siblings().removeClass('is-active');
         $(this).addClass('is-active');
       })
         .mouseleave(function () {
           $(this).removeClass('is-active');
         });*/
    }

    /*EXEMPLE MENU THREE LEVEL*/

    if ($('header').length) {
      $('.nav-mobile-menu .return svg').on('click', function () {
        $(this).closest('.nav-mobile-menu').removeClass('is-open');
        $(this).closest('.nav-mobile-menu').find('.menu-container-category').removeClass('is-selected');
      });

      $('.first-level .has-level a').on('click', function () {
        var category = $(this).parent().data('category');
        $('.second-level .menu-container .menu-container-category').each(function () {
          var resultCategory = $(this).data('category');

          if (resultCategory === category) {
            $('.second-level').addClass('is-open');
            $(this).addClass('is-selected');
          }
        });
      });

      $('.second-level .has-level a').on('click', function () {
        var category = $(this).parent().data('category');
        $('.third-level .menu-container .menu-container-category').each(function () {
          var resultCategory = $(this).data('category');

          if (resultCategory === category) {
            $('.third-level').addClass('is-open');
            $(this).addClass('is-selected');
          }
        });
      });
    }

    /*CLICK OUTSIDE TO CLOSE MENU*/
    $('body').on('click', function (e) {
      if (!$(e.target).closest('#header').length) {
        $('body').removeClass('overlay-open');
        $('.nav-mobile-menu').removeClass('is-open');
        $(this).find('#nav-icon').removeClass('open');
        $('.menu-container-category').removeClass('is-selected');
      }
    });

    /*BURGER MENU*/
    $('.burger').on('click', function (e) {
      if ($('.nav-mobile-menu').hasClass('is-open')) {
        $('.nav-mobile-menu').removeClass('is-open');
        $('body').removeClass('overlay-open');
        $(this).find('#nav-icon').removeClass('open');
        $('.menu-container-category').removeClass('is-selected');
        return;
      }
      $('.nav-mobile-menu.first-level').addClass('is-open');
      $('body').toggleClass('overlay-open');
      $(this).find('#nav-icon').toggleClass('open');
    });

    /*SLICK SLIDER EXEMPLE*/
    if (window.matchMedia('(max-width: 897px)').matches) {
      if ($('.slider-office').length) {
        $('.slider-office-container').each(function () {
          $(this).slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            speed: 500,
            fade: true,
            cssEase: 'linear',
            infinite: true,
            variableWidth: false,
            autoplay: true,
            autoplaySpeed: 5000,
            swipe: false,
            dots: false,
          });
        });
      }
    }

    /* ACCORDION */

    if ($('.accordion-block')) {
      $('.accordion-title').on('click', function (e) {
        $(this).closest('li').toggleClass('is-open');
      });
    }

    /* LIST FIX */

    if ($('ul li ul').length) {
      $('ul li ul').parent().addClass('ul-inside');
    }

    /* COLLAPSIBLE */

    if ($('.collapsible-list')) {
      $('.collapsible').on('click', function (e) {
        var $element = $(this);

        if ($element.hasClass('is-open')) {
          $element.removeClass('is-open');
          $element.find('.collapsible-content').css('max-height', '');
        }
        else {
          $('.collapsible-list .collapsible.is-open').removeClass('is-open').find('.collapsible-content').css('max-height', '');
          var $collapsibleContent = $element.find('.collapsible-content');
          $collapsibleContent.css('max-height', $collapsibleContent[0].scrollHeight);
          $element.addClass('is-open');
        }
      });
    }

    /* QUOTE */

    if ($('.quote-list').length) {
      $('.quote-list-slider').each(function () {
        $(this).slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          speed: 500,
          cssEase: 'linear',
          infinite: true,
          variableWidth: false,
          autoplay: true,
          autoplaySpeed: 5000,
          swipe: true,
          dots: true,
          adaptiveHeight: true
        });
      });
    }

    /* KEY FIGURES */

    if ($('.key-figures-container .key-figures-list-slider').length) {
      $('.key-figures-container .key-figures-list-slider').each(function () {
        $(this).slick({
          slidesToShow: 3,
          slidesToScroll: 3,
          arrows: true,
          speed: 500,
          cssEase: 'linear',
          infinite: true,
          variableWidth: false,
          autoplay: true,
          autoplaySpeed: 4000,
          swipe: true,
          dots: false,
          responsive: [
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2
              }
            },

            {
              breakpoint: 896,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
        });
      });
    }

    /* KEY FIGURES HOME */
    if ($('.key-figures-home-container .key-figures-list-slider').length) {
      $('.key-figures-home-container .key-figures-list-slider').each(function () {
        $(this).slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          speed: 500,
          cssEase: 'linear',
          infinite: true,
          variableWidth: false,
          autoplay: true,
          autoplaySpeed: 5000,
          swipe: true,
          dots: true,
          adaptiveHeight: true
        });
      });
    }

    /* SLIDER 3 */

    if ($('.slider-3-list-slider').length) {
      $('.slider-3-list-slider').each(function () {
        var slidesToScroll = $(this).hasClass('per1') ? 1 : 3;
        $(this).slick({
          slidesToShow: 3,
          slidesToScroll: slidesToScroll,
          arrows: true,
          speed: 500,
          cssEase: 'linear',
          infinite: true,
          variableWidth: false,
          swipe: true,
          dots: true,
          responsive: [
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 2,
                slidesToScroll: slidesToScroll > 2 ? 2 : slidesToScroll
              }
            },

            {
              breakpoint: 896,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
        });
      });
    }

    /* SLIDER IMG */

    if ($('.slider-img-list-slider').length) {
      $('.slider-img-list-slider').each(function () {
        $(this).slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          speed: 500,
          cssEase: 'linear',
          infinite: true,
          variableWidth: false,
          swipe: true,
          dots: true,
        });
      });
    }

    /* WRAP TABLE */

    if ($('.editor-wysiwyg table').length) {
      $('.editor-wysiwyg table').wrap('<div class="table-wrap"></div>');
    }

    /* NEWS */
    self.isLastPageNews();

  },

  isLastPageNews: function isLastPageNews() {
    if ($('.news-container .grid-content').find('.news-card.ended').length > 0)
      $('.load-more-news').hide('slow');
    else
      $('.load-more-news').show('slow');
  },

  load_more_news: function load_more_news() {
    var page = 1;
    var self = this;
    
    function loader() {
      $(document).on({
        ajaxStart: function () { $('.news-loader').show(); },
        ajaxStop: function () { $('.news-loader').hide(); }
      });
    }

    $(document).on('click', '.load-more-news', function (e) {
      e.preventDefault();
      page++;
      loader();

      var data = {
        'action': 'orpi_loadmore_news',
        'page': page,
        'category': $('.filters input:checked').val()
      };

      $.ajax({
        type: 'POST',
        url: '/wp/wp-admin/admin-ajax.php',
        dataType: 'html',
        data: data,
        success: function success(data) {
          var newNews = $(data).hide();
          $('.news-container .grid-content').append(newNews);
          newNews.show('slow');
          self.isLastPageNews();
        },
        error: function error(data) {
          console.log(data);
        }
      });
    });

    $(document).on('change', '.filters input[type=radio][name=filter-news]', function (e) {
      e.preventDefault();
      page = 1;
      loader();

      var data = {
        'action': 'orpi_loadmore_news',
        'page': 1,
        'category': $('.filters input:checked').val()
      };

      $('.news-container .grid-content').hide('slow');

      $.ajax({
        type: 'POST',
        url: '/wp/wp-admin/admin-ajax.php',
        dataType: 'html',
        data: data,
        success: function success(data) {
          $('.news-container .grid-content').html(data).show('slow');
          self.isLastPageNews();
        },
        error: function error(data) {
          console.log(data);
        }
      });
    });

  },
};

app.init();

// global custom functions, they can be called from anywhere within the project (useful for the back-end developers)
let customFunctions = {
  // global custom function example
  // to call it from anywhere : global.customFunction.afterAjaxExample();
  /*afterAjaxExample: function() {
   helpers.resizeImg('.media-block-news');
   }*/



};
// exports the elements that need to be accessed from somewhere else (in the "global" standalone object, cf. gulpfile)
module.exports = customFunctions;

